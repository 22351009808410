<template>
  <div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
// import Home from './components/home.vue'

export default {
  name: 'App'
}
</script>

