<template>
    <div class="c-layout-page">

        <div class="c-layout-breadcrumbs-1 c-fonts-uppercase c-fonts-bold">
            <div class="container">
                <div class="c-page-title c-pull-left">
                    <h3 class="c-font-uppercase c-font-sbold">Contact Us</h3>
                </div>
                <ul class="c-page-breadcrumbs c-theme-nav c-pull-right c-fonts-regular">
                    <li><a href="/" class="company_name">My Baja Trans</a></li>
                    <li>/</li>
                    <li>Contact Us</li>

                </ul>
            </div>
        </div>
        <div class="c-content-box c-size-md c-bg-img-top c-no-padding c-pos-relative">
            <div class="container">
                <div class="c-content-contact-1 c-opt-1">
                    <div class="row" data-auto-height=".c-height">
                        <div class="col-sm-8 c-desktop"></div>
                        <div class="col-sm-4">
                            <div class="c-body">
                                <div class="c-section">
                                    <h3 class="company">PT Bali Jaya Transindo</h3>
                                </div>
                                <div class="c-section">
                                    <div class="c-content-label c-font-uppercase c-font-bold c-theme-bg">Address</div>
                                    <p>Margomulyo Industri Indah No.3,<br>Balongsari, Kec. Tandes, Kota SBY,<br>Jawa Timur 60186</p>
                                </div>
                                <!-- <div class="c-section">
                                    <div class="c-content-label c-font-uppercase c-font-bold c-theme-bg">Contacts</div>
                                    <p><strong>T</strong> 800 123 0000<br><strong>F</strong> 800 123 8888</p>
                                </div> -->
                                <div class="c-section">
                                    <div class="c-content-label c-font-uppercase c-font-bold c-theme-bg">Social</div>
                                    <br>
                                    <ul class="c-content-iconlist-1 c-theme">
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-youtube-play"></i></a></li>
                                        <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="map">
            <!-- Home -->
                <iframe src="https://maps.google.com/maps?q=-7.249663576547364,112.67980059737296&z=19&output=embed" id="maps-holder" width="100%" height="760" frameborder="0" style="border:0"></iframe>
            </div>
        </div>
        <!-- <div class="c-content-box c-size-md c-bg-white">
            <div class="container">
                <div class="c-content-feedback-1 c-option-1">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="c-container c-bg-blue c-bg-img-bottom-right" style="background-image:url(../../assets/base/img/content/misc/feedback_box_1.png)">
                                <div class="c-content-title-1 c-inverse">
                                    <h3 class="c-font-uppercase c-font-bold">Need to know more?</h3>
                                    <div class="c-line-left"></div>
                                    <p class="c-font-lowercase">Try visiting our FAQ page to learn more about our
                                        greatest ever expanding theme</p>
                                    <a href="/" class="btn btn-md c-btn-border-2x c-btn-white c-btn-uppercase c-btn-square c-btn-bold">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="c-contact">
                                <div class="c-content-title-1">
                                    <h3 class="c-font-uppercase c-font-bold">Keep in touch</h3>
                                    <div class="c-line-left"></div>
                                    <p class="c-font-lowercase">
                                        Our helpline is always open to receive any inquiry or feedback.
                                        Please feel free to drop us an email from the form below and we will get back to
                                        you as soon as we can.
                                    </p>
                                </div>
                                <form action="#">
                                    <div class="form-group">
                                        <input type="text" placeholder="Your Name" class="form-control c-square c-theme input-lg">
                                    </div>
                                    <div class="form-group">
                                        <input type="text" placeholder="Your Email" class="form-control c-square c-theme input-lg">
                                    </div>
                                    <div class="form-group">
                                        <input type="text" placeholder="Contact Phone" class="form-control c-square c-theme input-lg">
                                    </div>
                                    <div class="form-group">
                                        <textarea rows="8" name="message" placeholder="Write comment here ..." class="form-control c-theme c-square input-lg"></textarea>
                                    </div>
                                    <button type="submit" class="btn c-btn-blue c-btn-uppercase btn-lg c-btn-bold c-btn-square">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>