<template>
    <div class="c-layout-page">
        <div class="c-layout-breadcrumbs-1 c-fonts-uppercase c-fonts-bold">
            <div class="container">
                <div class="c-page-title c-pull-left">
                    <h3 class="c-font-uppercase c-font-sbold">About Apps</h3>
                </div>
                <ul class="c-page-breadcrumbs c-theme-nav c-pull-right c-fonts-regular">
                    <li><a href="/" class="company_name">My Baja Trans</a></li>
                    <li>/</li>
                    <li>About Apps</li>

                </ul>
            </div>
        </div>
        <div id="feature-14-1" class="c-content-feature-14 c-bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <img src="https://cdn.dribbble.com/users/2253180/screenshots/15774969/media/56a6cce1177146ed5450e877e7b84eca.jpg?compress=1&resize=1200x900">
                    </div>
                    <div class="col-md-6">
                        <div class="c-content-title-3 c-feature-14-title c-theme-border">
                            <h3 class="c-left c-font-uppercase c-font-bold">Incredibly Versatile</h3>
                            <div class="c-line c-dot c-dot-left "></div>
                        </div>
                        <p class="c-left">
                            Content to Describe ...
                        </p>
                        <br>
                        <div class="c-feature-14-btn">
                            <a href="javascript:;" class="c-feature-14-btn btn c-btn btn-lg c-theme-btn c-btn-border-2x c-font-uppercase c-font-bold c-btn-square js-smoothscroll" data-target="feature-14-2">Next</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="feature-14-2" class="c-content-feature-14 c-bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="c-content-title-3 c-theme-border c-right">
                            <h3 class="c-right c-font-uppercase c-font-bold">Well Documented</h3>
                        </div>
                        <p class="c-right">
                            Content to Describe ...
                            <br> <br>
                            Content to Describe ...
                        </p>
                        <br>
                    </div>
                    <div class="col-md-6">
                        <img src="https://cdn.dribbble.com/users/1615584/screenshots/15571949/media/7e95f0fddb7957220033569815613b10.jpg?compress=1&resize=1200x900">
                    </div>
                </div>
            </div>
        </div>
        <div class="c-content-box c-size-md c-bg-white">
            <div class="container">
                <div class="c-content-feature-10">
                    <!-- Begin: Title 1 component -->
                    <div class="c-content-title-1">
                        <h3 class="c-font-uppercase c-center c-font-bold">Main Features</h3>
                        <div class="c-line-center c-theme-bg"></div>
                        <p class="c-font-center">Content to Describe ...</p>
                    </div>
                    <!-- End-->
                    <ul class="c-list">
                        <li>
                            <div class="c-card c-font-right wow animate fadeInLeft" style="visibility: visible; animation-name: fadeInLeft; opacity: 1;">
                                <i class="icon-trophy c-font-27 c-theme-font c-float-right c-border c-border-opacity"></i>
                                <div class="c-content c-content-right">
                                    <h3 class="c-font-uppercase c-font-bold">25 Totally Unique Homepages</h3>
                                    <p>
                                        Content to Describe ...
                                    </p>
                                </div>
                            </div>	
                            <div class="c-border-bottom c-bg-opacity-2"></div>
                        </li>
                        <div class="c-border-middle c-bg-opacity-2"></div>
                        <li>
                            <div class="c-card wow animate fadeInRight" style="visibility: visible; animation-name: fadeInRight; opacity: 1;">
                                <i class="icon-rocket c-font-27 c-theme-font c-float-left c-border c-border-opacity"></i>
                                <div class="c-content c-content-left">
                                    <h3 class="c-font-uppercase c-font-bold">Intuitive Code Structure</h3>					
                                    <p>
                                        Content to Describe ...
                                    </p>
                                </div>
                            </div>	
                            <div class="c-border-bottom c-bg-opacity-2"></div>
                        </li>
                    </ul>
                    <ul class="c-list">
                        <li>
                            <div class="c-card c-font-right wow animate fadeInLeft" style="visibility: visible; animation-name: fadeInLeft; opacity: 1;">
                                <i class="icon-layers c-font-27 c-theme-font c-float-right c-border c-border-opacity"></i>
                                <div class="c-content c-content-right">
                                    <h3 class="c-font-uppercase c-font-bold">10 Exclusive Slideshows</h3>
                                    <p>
                                        Content to Describe ...
                                    </p>
                                </div>
                            </div>	
                            <div class="c-border-bottom c-bg-opacity-2"></div>
                        </li>
                        <div class="c-border-middle c-bg-opacity-2"></div>
                        <li class="c-border-grey-1-5">
                            <div class="c-card wow animate fadeInRight" style="visibility: visible; animation-name: fadeInRight; opacity: 1;">
                                <i class="icon-present c-font-27 c-theme-font c-float-left c-border c-border-opacity"></i>
                                <div class="c-content c-content-left">
                                    <h3 class="c-font-uppercase c-font-bold">Huge &amp; Unlimited Updates</h3>					
                                    <p>
                                        Content to Describe ...
                                    </p>
                                </div>
                            </div>	
                            <div class="c-border-bottom c-bg-opacity-2"></div>
                        </li>
                    </ul>
                    <ul class="c-list">
                        <li>
                            <div class="c-card c-font-right wow animate fadeInLeft" style="visibility: visible; animation-name: fadeInLeft; opacity: 1;">
                                <i class="icon-book-open c-font-27 c-theme-font c-float-right c-border c-border-opacity"></i>
                                <div class="c-content c-content-right">
                                    <h3 class="c-font-uppercase c-font-bold">16 Unique Header Designs</h3>
                                    <p>
                                        Content to Describe ...
                                    </p>
                                </div>
                            </div>	
                            <div class="c-border-bottom c-bg-opacity-2 c-mobile"></div>
                        </li>
                        <div class="c-border-middle c-bg-opacity-2"></div>
                        <li>
                            <div class="c-card wow animate fadeInRight" style="visibility: visible; animation-name: fadeInRight; opacity: 1;">
                                <i class="icon-support c-font-27 c-theme-font c-float-left c-border c-border-opacity"></i>
                                <div class="c-content c-content-left">
                                    <h3 class="c-font-uppercase c-font-bold">Stunning Customer Support</h3>					
                                    <p>
                                        Content to Describe ...
                                    </p>
                                </div>
                            </div>	
                        </li>
                    </ul>
            
                </div>
            </div> 
        </div>
        <div class="c-content-box c-size-md c-bg-grey-1">
            <div class="container">
                <div class="c-content-subscribe-form-1 c-subscribe-form-light">
                    <div class="row">
                        <div class="col-sm-6">
                            <h3 class="c-title c-font-30 c-font-uppercase c-font-bold">Subscribe to our newsletter</h3>
                            <div class="c-body c-font-16 c-font-uppercase c-font-sbold">Content to Describe ...</div>
                        </div>
                        <div class="col-sm-6">
                            <form action="#">
                                <div class="input-group input-group-lg">
                                    <input type="text" class="form-control input-lg" placeholder="Your Email Here">
                                    <span class="input-group-btn">
                                        <button type="submit" class="btn c-btn-blue c-btn-uppercase btn-lg c-btn-sbold c-btn-square">SUBSCRIBE</button>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>