<template>
    <div class="c-layout-page">
        <div class="c-layout-breadcrumbs-1 c-fonts-uppercase c-fonts-bold">
            <div class="container">
                <div class="c-page-title c-pull-left">
                    <h3 class="c-font-uppercase c-font-sbold">Services</h3>
                </div>
                <ul class="c-page-breadcrumbs c-theme-nav c-pull-right c-fonts-regular">
                    <li><a href="/" class="company_name">My Baja Trans</a></li>
                    <li>/</li>
                    <li>Our Services</li>

                </ul>
            </div>
        </div>
        <div id="feature-16-1" class="c-content-feature-16 c-bg-img-center"
            style="background-image: url(dist/base/img/content/backgrounds/bg_footer_1.png)">
            <div class="container">
                <div class="row">
                    <div class="col-md-offset-7 col-md-5 col-xs-12">
                        <div class="c-feature-16-container c-bg-white c-bg-opacity-5">
                            <h2 class="c-feature-16-title c-font-bold c-font-uppercase">Kami Melayani Dengan Sepenuh Hati</h2>
                            <div class="c-feature-16-line c-theme-bg"></div>
                            <p class="c-feature-16-desc ">
                                Setiap hari adalah hari baru bagi kami dan kami bekerja sangat keras untuk memuaskan pelanggan kami di mana saja – <span class="company_name">BAJATRANS</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-content-box c-size-md c-no-padding c-bg-img-center"
            style="background-image: url(dist/base/img/content/stock3/61.jpg)">
            <div class="c-content-feature-13">
                <div class="row c-reset">
                    <div class="col-md-6 c-bg-dark">
                        <div class="c-feature-13-container">
                            <div class="c-content-title-1">
                                <h3 class="c-font-uppercase c-font-white c-font-bold">Transportasi <span
                                        class="c-theme-font">Darat</span></h3>
                                <div class="c-line-left c-theme-bg"></div>
                            </div>
                            <div class="row">
                                <div class="c-feature-13-tile">
                                    <i class="icon-size-fullscreen c-theme-font c-font-24"></i>
                                    <div class="c-feature-13-content">
                                        <h4 class="c-font-uppercase c-theme-font c-font-bold">Aman Dan Nyaman</h4>
                                        <p class="c-font-grey">
                                            Anda mendapat manfaat dari pengalaman kami dalam memberikan solusi efektif untuk rantai pasokan global yang kompleks dari beberapa perusahaan terbesar.
                                        </p>
                                    </div>
                                </div>
                                <div class="c-feature-13-tile">
                                    <i class="icon-puzzle c-theme-font c-font-24"></i>
                                    <div class="c-feature-13-content">
                                        <h4 class="c-font-uppercase c-theme-font c-font-bold">Pengiriman Cepat</h4>
                                        <p class="c-font-grey">
                                            Anda mendapat manfaat dari setiap inovasi, baik yang melibatkan perluasan sederhana untuk produk Angkutan Darat dan Laut kami, apakah itu berarti pengembangan pergudangan.
                                        </p>
                                    </div>
                                </div>
                                <div class="c-feature-13-tile">
                                    <i class="icon-picture c-theme-font c-font-24"></i>
                                    <div class="c-feature-13-content">
                                        <h4 class="c-font-uppercase c-theme-font c-font-bold">Layanan 24/7</h4>
                                        <p class="c-font-grey">
                                            Semuanya menjelaskan mengapa Anda akan menemukan tim dukungan luar biasa di Baja Trans yang siap menerapkan semangat mereka untuk solusi dalam mendukung bisnis Anda.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-content-box c-size-md c-no-padding c-bg-img-center"
            style="background-image: url(dist/base/img/content/stock3/laut.jpg)">
            <div class="c-content-feature-13">
                <div class="row c-reset">
                    <div class="col-md-6 col-md-offset-6 c-bg-white">
                        <div class="c-feature-13-container">
                            <div class="c-content-title-1">
                                <h3 class="c-font-uppercase c-font-bold">Transportasi <span
                                        class="c-theme-font">Laut</span></h3>
                                <div class="c-line-left c-theme-bg"></div>
                            </div>
                            <div class="row">
                                <div class="c-feature-13-tile">
                                    <i class="icon-users c-theme-font c-font-24"></i>
                                    <div class="c-feature-13-content">
                                        <h4 class="c-font-uppercase c-theme-font c-font-bold">Varietas</h4>
                                        <p class="c-font-dark">
                                            Menawarkan berbagai jenis kendaraan yang kami miliki untuk memenuhi kebutuhan pelanggan kami dalam semua jenis pengiriman.
                                        </p>
                                    </div>
                                </div>
                                <div class="c-feature-13-tile">
                                    <i class="icon-heart c-theme-font c-font-24"></i>
                                    <div class="c-feature-13-content">
                                        <h4 class="c-font-uppercase c-theme-font c-font-bold">Kapasitas</h4>
                                        <p class="c-font-dark">
                                            Kapasitas angkut merupakan prioritas penting untuk menjaga keselamatan, setiap jenis kendaraan memiliki kapasitas angkut yang berbeda sesuai dengan kebutuhan.
                                        </p>
                                    </div>
                                </div>
                                <div class="c-feature-13-tile">
                                    <i class="icon-fire c-theme-font c-font-24"></i>
                                    <div class="c-feature-13-content">
                                        <h4 class="c-font-uppercase c-theme-font c-font-bold">Jangkauan Luas</h4>
                                        <p class="c-font-dark">
                                            Melayani berbagai kebutuhan pengiriman antar kota ke antar pulau dengan tujuan di seluruh Indonesia.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-content-box c-size-md c-bg-parallax"
            style="background-image: url(dist/base/img/content/backgrounds/bg-87.jpg)">
            <div class="container">
                <div class="c-content-feature-9">
                    <!-- Begin: Title 1 component -->
                    <div class="c-content-title-1">
                        <h3 class="c-font-uppercase c-center c-font-bold">Bergabung Bersama Kami</h3>
                        <div class="c-line-center c-theme-bg"></div>
                        <p class="c-font-center">Menjadi bagian dari <span class="company_name">My Baja Trans</span></p>
                    </div>
                    <!-- End-->
                    <div class="row">
                        <div class="col-md-6">
                            <div class="c-content-feature-5">
                                <div class="c-content-title-1">
                                    <h3 class="c-left c-font-dark c-font-uppercase c-font-bold">SHIPPER</h3>
                                </div>
                                <div class="c-text c-font-16 c-font-sbold c-font-uppercase">
                                    Dapatkan akomodasi terbaik untuk barang-barang Anda dari armada kami
                                </div>
                                <a href="/shipper"><button type="button" class="btn c-btn-uppercase btn-md c-btn-sbold c-btn-square c-btn-blue">
                                    JADI SHIPPER
                                </button></a>
                                <img class="c-photo img-responsive" style="width: 310px" src="dist/base/img/content/stock3/shipper.jpg" alt="">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="c-content-feature-5">
                                <div class="c-content-title-1">
                                    <h3 class="c-left c-font-dark c-font-uppercase c-font-bold">VENDOR</h3>
                                </div>
                                <div class="c-text c-font-16 c-font-sbold c-font-uppercase">
                                    Maksimalkan muatan armada Anda dengan bergabung bersama kami
                                </div>
                                <a href="/vendor"><button type="button" class="btn c-btn-uppercase btn-md c-btn-sbold c-btn-square c-btn-blue">JADI VENDOR</button></a>
                                <img class="c-photo img-responsive" style="width: 310px" src="dist/base/img/content/stock3/40.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-content-box c-size-md c-bg-grey-1">
            <div class="container">
                <div class="c-content-subscribe-form-1 c-subscribe-form-light">
                    <div class="row">
                        <div class="col-sm-6">
                            <h3 class="c-title c-font-30 c-font-uppercase c-font-bold">Subscribe to our newsletter</h3>
                            <div class="c-body c-font-16 c-font-uppercase c-font-sbold">
                                Dapatkan info menarik dari kami melalui newsletter yang kami bagikan secara berkala
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <form action="#">
                                <div class="input-group input-group-lg">
                                    <input type="text" class="form-control input-lg" placeholder="Your Email Here">
                                    <span class="input-group-btn">
                                        <button type="submit" class="btn c-btn-blue c-btn-uppercase btn-lg c-btn-sbold c-btn-square">SUBSCRIBE</button>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>