<template>
    <div class="c-content-box c-size-lg c-bg-img-top" style="background-image: url(dist/base/img/content/backgrounds/bg-65.jpg)">
        <div class="container">
            <div class="c-content-feature-6">
                <!-- Begin: Title 1 component -->
                <div class="c-content-title-1">
                    <div class="c-line-center c-theme-bg"></div>
                </div>
                <!-- End-->
                <ul class="c-list">
                    <li>
                        <div class="c-card c-bg-opacity-1">
                            <h3 class="c-font-uppercase c-font-bold">Register Berhasil</h3>
                            <p>
                                Terima kasih telah melakukan register. Kami telah mengirimkan balasan ke Email Anda. Mohon untuk cek Email Anda.
                            </p>
                            <p>
                                <a href="/">
                                    <button type="button" class="btn c-btn-blue btn-md c-btn-uppercase c-btn-bold c-btn-square c-btn-login">Kembali</button>
                                </a>
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>