import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)

import router from './router/index'

import GAuth from 'vue3-google-oauth2'

const gAuthOptions = { clientId: '114966290313-oidpmepkseoos6fhpplrrhpaeq1d0mfp.apps.googleusercontent.com', scope: 'email', prompt: 'consent', fetch_basic_profile: true }
app.use(GAuth, gAuthOptions)

app.use(router).mount('#app')
