<template>
    <div class="c-layout-page">
        <div class="c-content-box c-size-md c-no-padding c-bg-white">
            <div class="c-content-feature-4">
                <div class="c-bg-parallax c-feature-bg c-content-right c-diagonal c-border-left-white" style="background-image: url(dist/base/img/content/home/home-1.jpg)"></div>
                <div class="c-content-area c-content-left"></div>
                <div class="container">
                    <div class="c-feature-content c-left">
                        <div class="c-content-v-center">
                            <div class="c-wrapper">
                                <div class="c-body">
                                    <div class="c-content-title-1 wow animate fadeInDown"
                                        style="visibility: visible; animation-name: fadeInDown; opacity: 1;">
                                        <h3 class=" c-font-bold c-left company_name">My Baja Trans</h3>
                                    </div>
                                    <div class="c-content">
                                        <p class="c-margin-b-30 c-left wow animate fadeInDown"
                                            style="visibility: visible; animation-name: fadeInDown; opacity: 1;">
                                            Menyediakan solusi untuk pengiriman barang melalui darat maupun laut
                                        </p>
                                        <router-link to="/shipper"><button class="btn btn-lg c-btn-blue c-btn-border-2x c-btn-square c-btn-bold wow animate fadeIn" style="visibility: visible; animation-name: fadeIn; opacity: 1;">
                                            JADI SHIPPER
                                        </button></router-link>
                                        <router-link to="/vendor"><button class="btn btn-lg c-btn-blue c-btn-square c-btn-bold wow animate fadeIn" data-wow-delay="0.2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeIn; opacity: 1; margin-left: 5px">
                                            JADI VENDOR
                                        </button></router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="c-content-feature-14 c-bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="c-content-title-3 c-theme-border c-right">
                            <h3 class="c-right c-font-uppercase c-font-bold company_name">My Baja Trans</h3>
                        </div>
                        <p class="c-right">
                            Melayani kebutuhan transportasi kepada pelanggan lebih dari 37 tahun.
                            Dengan bertumbuhnya usia, kami menawarkan beragam solusi
                            kebutuhan transportasi yang handal (on-time delivery).
                        </p>
                        <br>
                        <div class="row">
                            <div class="col-xs-4">
                                <div class="c-feature-gallery">
                                    <img
                                        src="https://images.unsplash.com/photo-1571989928541-674d0cf46c4a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=582&q=80">
                                </div>
                            </div>
                            <div class="col-xs-4">
                                <div class="c-feature-gallery">
                                    <img
                                        src="https://images.unsplash.com/photo-1583857671904-a716bf4ee5d8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=580&q=80">
                                </div>
                            </div>
                            <div class="col-xs-4">
                                <div class="c-feature-gallery">
                                    <img
                                        src="https://images.unsplash.com/photo-1492168732976-2676c584c675?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=580&q=80">
                                </div>
                            </div>

                        </div>
                        <!-- <div class="c-right c-feature-14-btn">
                            <a href="javascript:;"
                                class="btn c-btn btn-lg c-theme-btn c-font-uppercase c-font-bold c-btn-square">Learn
                                more</a>
                        </div> -->
                    </div>
                    <div class="col-md-6">
                        <img src="dist/base/img/content/home/home-1.jpg">
                    </div>
                </div>
            </div>
        </div>

        <!-- BEGIN: CONTENT/FEATURES/FEATURES-9 -->
        <div class="c-content-box c-size-md c-bg-parallax"
            style="background-image: url(dist/base/img/content/backgrounds/bg-87.jpg)">
            <div class="container">
                <div class="c-content-feature-9">
                    <!-- Begin: Title 1 component -->
                    <div class="c-content-title-1">
                        <h3 class="c-font-uppercase c-center c-font-bold">Kirim Barang Dengan Mudah</h3>
                        <div class="c-line-center c-theme-bg"></div>
                        <p class="c-font-center">Mengapa mengirim barang dengan <span class="company_name">My Baja Trans</span> begitu mudah?</p>
                    </div>
                    <!-- End-->
                    <ul class="c-list">
                        <li class="wow animate fadeInUp">
                            <div class="c-card">
                                <i class="icon-rocket c-font-blue-1-5 c-font-22 c-bg-white c-float-left"></i>
                                <div class="c-content c-content-left">
                                    <h3 class="c-theme-font c-font-uppercase c-font-bold">Full Sass Support</h3>
                                    <p>
                                        Content to Describe ...
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="wow animate fadeInUp">
                            <div class="c-card">
                                <i class="icon-user c-font-blue-1-5 c-font-27 c-bg-white c-float-left"></i>
                                <div class="c-content c-content-left">
                                    <h3 class="c-theme-font c-font-uppercase c-font-bold">Group Tasks</h3>
                                    <p>
                                        Content to Describe ...
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="wow animate fadeInUp">
                            <div class="c-card">
                                <i class="icon-basket c-font-blue-1-5 c-font-27 c-bg-white c-float-left"></i>
                                <div class="c-content c-content-left">
                                    <h3 class="c-theme-font c-font-uppercase c-font-bold">Responsive UI</h3>
                                    <p>
                                        Content to Describe ...
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- END: CONTENT/FEATURES/FEATURES-9 -->

        <!-- BEGIN: CONTENT/SLIDERS/CLIENT-LOGOS-2 -->
        <div class="c-content-box c-size-md c-bg-white">
            <div class="container">
                <!-- Begin: Testimonals 1 component -->
                <div class="c-content-client-logos-slider-1  c-bordered" data-slider="owl">
                    <!-- Begin: Title 1 component -->
                    <div class="c-content-title-1">
                        <h3 class="c-center c-font-uppercase c-font-bold"><span class="company_name"> Baja Trans </span> Dipercaya Oleh <br> Ratusan
                            Shipper di Indonesia</h3>
                        <div class="c-line-center c-theme-bg"></div>
                    </div>
                    <!-- End-->

                    <!-- Begin: Owlcarousel -->

                    <div class="owl-carousel owl-theme c-theme owl-bordered1 c-owl-nav-center" data-items="6" data-desktop-items="4" data-desktop-small-items="3" data-tablet-items="3" data-mobile-small-items="2" data-slide-speed="5000" data-rtl="false">
                        <div class="item">
                            <a href="#"><img style="width: 160px; height: 50px" src="dist/base/img/content/client-logos/gg.jpg" alt="" /></a>
                        </div>
                        <div class="item">
                            <a href="#"><img style="width: 160px; height: 50px" src="dist/base/img/content/client-logos/Sinarmas.png" alt="" /></a>
                        </div>
                        <div class="item">
                            <a href="#"><img style="width: 160px; height: 50px" src="dist/base/img/content/client-logos/DanoneLogo2.gif" alt="" /></a>
                        </div>
                        <div class="item">
                            <a href="#"><img style="width: 120px; height: 50px" src="dist/base/img/content/client-logos/gds.png" alt="" /></a>
                        </div>
                        <div class="item">
                            <a href="#"><img style="width: 60px; height: 50px" src="dist/base/img/content/client-logos/semen-gresik.png" alt="" /></a>
                        </div>
                        <div class="item">
                            <a href="#"><img style="width: 110px; height: 50px" src="dist/base/img/content/client-logos/ks.png" alt="" /></a>
                        </div>
                        <div class="item">
                            <a href="#"><img style="width: 150px; height: 50px" src="dist/base/img/content/client-logos/petro.png" alt="" /></a>
                        </div>
                    </div>
                    <!-- End-->
                </div>
                <!-- End-->
            </div>
        </div><!-- END: CONTENT/SLIDERS/CLIENT-LOGOS-2 -->
        <!-- END: PAGE CONTENT -->
        <div class="c-content-box c-size-md c-bg-parallax"
            style="background-image: url(dist/base/img/content/backgrounds/bg-87.jpg)">
            <div class="container">
                <div class="c-content-feature-9">
                    <!-- Begin: Title 1 component -->
                    <div class="c-content-title-1">
                        <h3 class="c-font-uppercase c-center c-font-bold">Bergabung Bersama Kami</h3>
                        <div class="c-line-center c-theme-bg"></div>
                        <p class="c-font-center">Menjadi bagian dari <span class="company_name"> My Baja Trans</span></p>
                    </div>
                    <!-- End-->
                    <div class="row">
                        <div class="col-md-6">
                            <div class="c-content-feature-5">
                                <div class="c-content-title-1">
                                    <h3 class="c-left c-font-dark c-font-uppercase c-font-bold">SHIPPER
                                    </h3>
                                </div>
                                <div class="c-text c-font-16 c-font-sbold c-font-uppercase">
                                    Dapatkan akomodasi terbaik untuk barang-barang Anda dari armada kami
                                </div>
                                <a href="/shipper">
                                    <button type="button" class="btn c-btn-uppercase c-btn-blue btn-md c-btn-sbold c-btn-square">JADI SHIPPER</button>
                                </a>
                                <img class="c-photo img-responsive" style="width: 310px" src="dist/base/img/content/stock3/shipper.jpg" alt="">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="c-content-feature-5">
                                <div class="c-content-title-1">
                                    <h3 class="c-left c-font-dark c-font-uppercase c-font-bold">VENDOR
                                    </h3>
                                </div>
                                <div class="c-text c-font-16 c-font-sbold c-font-uppercase">
                                    Maksimalkan muatan armada Anda dengan bergabung bersama kami
                                </div>
                                <a href="/vendor">
                                    <button type="submit" class="btn c-btn-uppercase btn-md c-btn-sbold c-btn-square c-btn-blue">JADI VENDOR</button>
                                </a>
                                <img class="c-photo img-responsive" style="width: 310px" src="dist/base/img/content/stock3/40.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-content-box c-size-md c-bg-grey-1">
            <div class="container">
                <div class="c-content-subscribe-form-1 c-subscribe-form-light">
                    <div class="row">
                        <div class="col-sm-6">
                            <h3 class="c-title c-font-30 c-font-uppercase c-font-bold">Subscribe to our newsletter</h3>
                            <div class="c-body c-font-16 c-font-uppercase c-font-sbold">
                                Dapatkan info menarik dari kami melalui newsletter yang kami bagikan secara berkala
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <form action="#">
                                <div class="input-group input-group-lg">
                                    <input type="text" class="form-control input-lg" placeholder="Your Email Here">
                                    <span class="input-group-btn">
                                        <button type="submit"
                                            class="btn c-btn-blue c-btn-uppercase btn-lg c-btn-sbold c-btn-square">SUBSCRIBE</button>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>