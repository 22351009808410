import {createRouter, createWebHistory} from 'vue-router'

import Home from '../components/home.vue'
import Shipper from '../components/shipper.vue'
import Vendor from '../components/vendor.vue'
import OurServices from '../components/our-services.vue'
import AboutApps from '../components/about-apps'
import ContactUs from '../components/contact-us.vue'
import Registered from '../components/register.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/shipper',
        name: 'Shipper',
        component: Shipper
    },
    {
        path: '/vendor',
        name: 'Vendor',
        component: Vendor
    },
    {
        path: '/our-services',
        name: 'OurServices',
        component: OurServices
    },
    {
        path: '/about-apps',
        name: 'AboutApps',
        component: AboutApps
    },
    {
        path: '/contact-us',
        name: 'ContactUs',
        component: ContactUs
    },
    {
        path: '/registered',
        name: 'Registered',
        component: Registered
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
