<template>
    <div class="c-layout-page">
        <!-- <div class="c-layout-breadcrumbs-1 c-fonts-uppercase c-fonts-bold">
            <div class="container">
                <div class="c-page-title c-pull-left">
                    <h3 class="c-font-uppercase c-font-sbold">Vendor</h3>
                </div>
                <ul class="c-page-breadcrumbs c-theme-nav c-pull-right c-fonts-regular">
                    <li><a href="/" class="company_name">My Baja Trans</a></li>
                    <li>/</li>
                    <li>Vendor</li>

                </ul>
            </div>
        </div> -->
        <div class="c-content-box c-bg-white-1">
            <div class="container">
                <div class="c-content-bar-2 c-opt-1">
                    <div class="row" data-auto-height="true">
                        <div class="col-md-6">
                            <!-- Begin: Title 1 component -->
                            <br>
                            <div class="c-content-title-1" data-height="height" style="height: 167px;">
                                <h3 class=" c-font-bold"><span class="company_name"> Baja Trans</span> : Shipper</h3>
                                <p class=" c-font-sbold" style="font-size: 20px">
                                    Kami mendukung pengiriman melalui jalur darat maupun laut. Pengiriman untuk besi
                                    maupun baja, produk curah sampai bahan kimia.
                                </p>
                            </div>
                            <!-- End-->
                        </div>
                        <div class="col-md-6">
                            <!-- <div class="c-content-v-center c-bg-custom-palet custom-panel" data-height="height" style="height: 167px;">
                                <div class="c-wrapper">
                                    <div class="c-body">
                                        <h3 class="c-font-white c-font-bold">
                                            ARMADA TERBAIK DARI MY BAJA TRANS SIAP MEMBANTU PENGIRIMANMU.
                                        </h3>
                                    </div>
                                </div>
                            </div> -->
                            <img src ="dist/base/img/content/home/home-4.png" style="width: 75%; height: 75%; margin-top: 0px; margin-bottom: 0px" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="c-content-box c-size-md c-bg-blue">
            <div class="container">
                <div class="c-content-bar-2 c-opt-1">
                    <div class="row" data-auto-height="true">
                        <div class="col-md-5">
                            <!-- Begin: Title 1 component -->
                            <div class="c-content-title-1" data-height="height" style="height: 167px;">
                                <h3 class=" c-font-bold" style="color: white">Jadi Shipper!</h3>
                                <p class=" c-font-sbold" style="color: white; font-size: 20px">
                                    Anda akan bekerja sama dengan armada terbaik yang akan membantu Anda mengirimkan barang ke seluruh wilayah Indonesia
                                </p>
                            </div>
                            <br>
                            <div class="custom-image">
                                <img src="dist/base/img/shipper.png" style="width: 95%; height: 95%" alt="">
                            </div>
                            <!-- End-->
                        </div>
                        <div class="col-md-6">
                            <div class="panel panel-default custom-panel">
                                <div class="panel-body" v-bind:class="{ load: isLoaded }">
                                    <!-- <p align="center" style="font-size: 30px">COMING SOON ..</p> -->
                                    <div class="modal-body">
                                        <div class="lds-dual-ring" v-show="loader"></div>
                                        <h3 class="c-font-24 c-font-sbold">Bergabung menjadi Shipper!</h3>
                                        <p>Cari akomodasi untuk pengiriman lebih cepat!</p>
                                        <form>
                                            <div class="form-group">
                                                <label for="nama" class="hide">Nama Anda</label>
                                                <input type="text" class="form-control input-lg c-square" name="name" v-model="form.name" placeholder="Nama Anda">
                                                <span class="text-danger text-sm" ref="name" style="display: none">Nama tidak boleh kosong.</span>
                                            </div>
                                            <div class="form-group">
                                                <label for="email" class="hide">Email</label>
                                                <input type="email" class="form-control input-lg c-square" name="email" v-model="form.email" placeholder="Email">
                                                <span class="text-danger text-sm" ref="email" style="display: none">Email tidak boleh kosong.</span>
                                            </div>
                                            <div class="form-group">
                                                <label for="telepon" class="hide">Nomor Telepon</label>
                                                <input type="text" class="form-control input-lg c-square" name="phone" v-model="form.phone" id="phone" placeholder="Nomor Telepon">
                                                <span class="text-danger text-sm" ref="phone" style="display: none">Nomor telepon tidak boleh kosong.</span>
                                            </div>
                                            <div class="form-group">
                                                <label for="password" class="hide">Password</label>
                                                <input type="password" class="form-control input-lg c-square" name="password" v-model="form.password" id="password" placeholder="Password">
                                                <span class="text-danger text-sm" ref="password" style="display: none">Password tidak boleh kosong.</span>
                                            </div>
                                            <div class="form-group">
                                                <label for="re-password" class="hide">Re-Password</label>
                                                <input type="password" class="form-control input-lg c-square" @keyup="checkPassword" name="re_password" v-model="re_password" id="re_password" placeholder="Re-Password">
                                                <span class="text-danger text-sm" ref="re_password" style="display: none">Re-password tidak boleh kosong.</span>
                                                <span class="text-danger text-sm" ref="check_password" style="display: none">Password tidak sama.</span>
                                            </div>
                                            <div class="form-group">
                                                <button type="button" @click.prevent="submit" class="btn c-theme-btn btn-md c-btn-uppercase c-btn-bold c-btn-square c-btn-login">Daftar Shipper</button>
                                            </div>
                                            <div class="clearfix">
                                                <div
                                                    class="c-content-divider c-divider-sm c-icon-bg c-bg-grey c-margin-b-20">
                                                    <span>or signup with</span>
                                                </div>
                                                <ul class="c-content-list-adjusted">
                                                    <!-- <li>
                                                        <a class="btn btn-block c-btn-square btn-social btn-twitter">
                                                            <i class="fa fa-twitter"></i>
                                                            Twitter
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="btn btn-block c-btn-square btn-social btn-facebook">
                                                            <i class="fa fa-facebook"></i>
                                                            Facebook
                                                        </a>
                                                    </li> -->
                                                    <li>
                                                        <a @click="handleClickSignIn" class="btn btn-block c-btn-square btn-social btn-google">
                                                            <i class="fa fa-google"></i>
                                                            Google
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { inject, toRefs } from "vue";
import moment from 'moment'

export default {
    props: {
        msg: String,
    },
    data() {
        return {
            form: {
                name: null,
                email: null,
                phone: null,
                password: null,
                is_shipper: true,
                is_vendor: false,
                is_approved: false
            },
            re_password: "",
            loader: false,
            isLoaded: false
        }
    },
    methods: {
        async handleClickSignIn(){
            try {
                const googleUser = await this.$gAuth.signIn();
                if (!googleUser) {
                    return null;
                }
                const profile = googleUser.getBasicProfile();
                this.form.name = profile.getName();
                this.form.email = profile.getEmail();
                let time = moment(new Date()).format("Hmmss")
                let date = moment(new Date()).format("DD")
                this.form.password = time+date
                this.store();
            } catch (error) {
                //on fail do something
                console.error(error);
                return null;
            }
        },
        setup(props) {
            const { isSignIn } = toRefs(props);
            const Vue3GoogleOauth = inject("Vue3GoogleOauth");
            const handleClickLogin = () => {};
            return {
                Vue3GoogleOauth,
                handleClickLogin,
                isSignIn,
            };
        },
        checkPassword() {
            if(this.form.password != this.re_password){
                this.$refs.check_password.style.display = "block"
            }
            else {
                this.$refs.check_password.style.display = "none"
            }
        },
        submit() {
            if(this.form.name == '' || this.form.email == '' || this.form.phone == '' || this.form.password == '' || this.re_password == ''){
                if(this.form.name == ''){
                    this.$refs.name.style.display = "block"
                }
                if(this.form.email == ''){
                    this.$refs.email.style.display = "block"
                }
                if(this.form.phone == ''){
                    this.$refs.phone.style.display = "block"
                }
                if(this.form.password == ''){
                    this.$refs.password.style.display = "block"
                }
                if(this.re_password == ''){
                    this.$refs.re_password.style.display = "block"
                }
            }
            else {
                this.store();
            }
        },
        store() {
            const headers = {
                'Content-Type': 'application/json'
            }
            // axios.post("http://bcs-logistics.local/user/register", this.form, {
            //     headers: headers
            // })
            this.isLoaded = true
            this.loader = true
            axios.post(apiUrl+"/user/register", this.form, {
                headers: headers
            })
            .then((res) => {
                this.$router.push('/registered')
                console.log(res);
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }
}
</script>
<style scoped>
    .lds-dual-ring {
        margin: auto;
        position: absolute;
        display: inline-block;
        width: 80px;
        height: 80px;
        left: 25%;
        right: 25%;
        top: 25%;
        bottom: 25%;
    }
    .lds-dual-ring:after {
        content: " ";
        display: block;
        clear: both;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #000;
        border-color: #000 transparent #000 transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .load {
        opacity: 0.5;
    }
</style>